.report-span {
  font-weight: 700;
  /* margin-top: 20px; */
  margin-left: 5px;
  vertical-align: bottom;
  font-size: 21px;
  color: #000000a3;
  margin-bottom: 17px;
}

.report-title-span {
  font-weight: 700;
  /* margin-top: 20px; */
  margin-left: 5px;
  vertical-align: bottom;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000a3;
  margin-bottom: 17px;
}

.header-btn {
  background: #72c8cc;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  font-size: 0.75rem;
  width: 4.8rem;
}

.header-btn:hover {
  -webkit-filter: drop-shadow(0 0 5px grey);
  filter: drop-shadow(0 0 5px grey);
  filter: drop-shadow(0 0 0 5px grey);
}
