.Header {
	height: 45px;
	width: 97%;
	box-sizing: border-box;
	display: flex;
	// flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.textSection {
	width: 40%;
	height: 45px;
	display: flex;
	align-items: center;
}
.heading {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: #000000;
	opacity: 0.7;
}
.title {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #828282;
	margin-left: 15px;
}

.dropdownSection {
}
.dropdown {
	border: none;
	padding-right: 5px;
	color: #828282;
	font-size: 14px;
}
.dropdown:focus {
	outline: none;
}
