.Main {
	width: 97%;
	min-height: 100%;
	// margin-bottom: 500px;
}
.tableSection {
	margin-top: 20px;
	width: 100%;
	min-height: 50%;
	padding-bottom: 150px;
}
.table {
	width: 100%;
	border: 1px solid #efefef;
	border-collapse: separate;
	border-spacing: 0;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	overflow: auto;
}

.headingRow {
	background: #efefef;
	width: 100%;
	// padding: 0 5px;
}

.tableHtext {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 100%;
	color: #000000;
	opacity: 0.5;
	height: 38px;
	padding-left: 13px;
}
.serialNo {
	padding-left: 0px;
	border-top-left-radius: 12px;
	text-align: center;
	font-size: 15px;
	// border: 1px solid red;
	padding: 0 4px;
}
.lastHeading {
	border-top-right-radius: 12px;
}
.contentRow {
	// border: 2px solid red;
}
.contentRow:hover {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.tableData {
	padding-left: 13px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 100%;
	color: #000000;
	opacity: 0.5;
	height: 38px;
	// border-collapse: collapse;
	border: 1px solid rgb(239, 239, 239, 1);
}
.tableData:hover {
	// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.serialData {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 0;
	font-size: 15px;
	cursor: pointer;
}
.mainFilterData {
	min-width: 40%;
	display: flex;
	flex-direction: column;
	margin-left: 20px;
}
.filter {
	// width: ;
	display: none;
}
.filterAdded {
	display: block;
	// margin-top: 10px;
	// margin-bottom: 5px;
}
.filterAdded:hover {
	// opacity: 0.9;
	// box-shadow: 1px 1px 2px #000000;
}
.addEvent {
	width: 98%;
	// height: 50%;
	left: 0px;
	top: 0px;
	background: #ffffff;
	border: 1px dashed rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	// border: 1px solid red;
}
.addEvent:hover {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.plusIcon {
	color: #000000;
	margin-left: 8px;
	opacity: 0.5;
	font-size: 22px;
}
.addText {
	color: #000000;
	margin-left: 8px;
	opacity: 0.5;
	font-size: 14px;
}
.headSection {
	width: 100%;
	// height: 70px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: row;
	align-items: center;
	// justify-content: center;
}
.headTitle {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 100%;
	color: #000000;
	opacity: 0.5;
}

.loader {
	// border: 1px solid red;
	width: 100%;
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.loaderCircle {
		// border: 1px solid red;
		border: 5px solid #f3f3f3;
		border-radius: 50%;
		border-top: 5px solid #bb6bd9;
		width: 50px;
		height: 50px;
		animation: spin 1s linear infinite;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.loaderText {
		color: #bb6bd9;
		font-size: 25px;
		font-weight: bold;
	}
}
.goButton {
	background: #bb6bd9;
	width: 92px;
	height: 42px;
	color: #fff;
	border-radius: 40px;
	margin-left: 20px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.goButton:hover {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.footer {
	position: fixed;
	width: 86vw;
	height: 72px;
	right: 0;
	bottom: 0;
	background: #fbfbfb;
	box-shadow: 0px -2px 24px rgba(0, 0, 0, 0.08);
	// border: 7px solid red;
	display: flex;
	justify-content: center;
	align-items: center;
}
.footerButton {
	width: 162px;
	height: 40px;
	border: 1px solid #dfdfdf;
	border-radius: 40px;
	font-size: 12px;
	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.footerButton:hover {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.toggleButtonDiv {
	// border: 1px solid red;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
