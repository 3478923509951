.main-panel {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 430px;
  margin-left: 30px;
  margin-bottom: 80px;
  margin-right: 20px;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  position: relative;
  margin-top: 30px;
  flex-wrap: wrap;
}

.select-chart {
  height: 30px;
  width: 120px;
}

.loading-div {
  pointer-events: none;
  opacity: 0.7;
}

.chart-selection {
  width: 120px;
  height: 50px;
  border-radius: 40px;
  margin-left: 15px;
  margin-right: 20px;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  color: #828282;
  margin-top: 10px;
}

.chartType-selection {
  width: 160px;
  height: 60px;
  margin: 16px;
  border-radius: 20px;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  color: #828282;
  text-align: center;
  position: absolute;
  top: -22%;
}

.chart-headerDiv {
  // border: 2px solid black;
  top: -25%;
  position: absolute;
  margin-top: 30px;
}
