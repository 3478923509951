.selection-modifier-insight {
  justify-content: center;
  align-items: center;
  width: 8.5vw;
  //   width: auto;
  max-width: 137px;
  height: 40px;
  margin-left: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  margin-right: 0.15vw;
  border: 1px solid rgba(170, 170, 170, 0.418);
  box-sizing: border-box;
  border-radius: 13px;
  color: #828282;
  text-align: center;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.featureType-selection {
  border: none;
  text-decoration: underline;
  font-weight: 500;
  height: 40px;
  width: 4.6vw;
  justify-self: center;
  align-self: center;
  color: #828282;
  border-radius: 10px;
  margin-bottom: 10px;
}

.go-button-funnel {
  width: 100px;
  border-radius: 10px;
  background: #72c8cc;
  color: white;
  font-weight: 700;
  border: none;
  height: 34px;
}

.count-selection {
  border: none;
  // text-decoration: underline;
  font-weight: 500;
  height: 30px;
  width: 4.95vw;
  justify-self: center;
  align-self: flex-start;
  color: #828282;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 30px;
  // margin-bottom: 4px;
}
