.event-option_box::after {
  /* position: relative; */
  content: "";
  width: 0;
  height: 0;
  margin-left: 2.5em;
  border: 0.5em solid transparent;
  border-left-color: #bb6cd9;
  transform: rotate(90deg);
  transition: transform 0.25s;
  margin-top: 0.55em;
}
