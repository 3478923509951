.querySelector-parent-btn {
  background: white;
  color: #828282;
  width: 18rem;
  border-radius: 10px;
  border: 0.5px solid rgba(53, 53, 53, 0.3);
  font-size: 0.9rem;
  padding: 5px;
  text-align: left;
  font-weight: 500;
  padding-left: 18px;
  margin-top: 5px;
}
.querySelector-parent-btn:hover {
  font-weight: 600;
  color: #bb6bd9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.clear-icon-btn {
  transform: scale(1.1);
  border-radius: 4px;
  padding: 6px;
  stroke: rgba(95, 92, 92, 0.521);
  cursor: pointer;
}
.clear-icon-btn:hover {
  background-color: rgba(255, 110, 135, 0.418);
}
.filter-icon-btn {
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  margin-right: 7px;
}

.filter-icon-btn:hover {
  background-color: #bc92ff6b;
  color: "green";
}
.query-selector-go-btn {
  background: #72c8cc;
  color: white;
  font-weight: 600;
  width: 6rem;
  border-radius: 10px;
  border: none;
  padding: 4px;
  margin-bottom: 10px;
}

.filter-selector-input {
  border: 1px solid rgba(202, 202, 202, 0.47);
  border-radius: 10px;
  padding: 6px 6px 6px 12px;
  margin-bottom: 8px;
  width: 11rem;
  font-size: 14px;
  height: 36px;
  margin-right: 5px;
  margin-left: 0.2rem;
  background-color: #efefef7d;
  color: rgb(95, 94, 94);
  font-weight: 500;
}
