// .headSection {
// 	width: 100%;
// 	// height: 70px;
// 	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// }
// .headTitle {
// 	font-family: Montserrat;
// 	font-style: normal;
// 	font-weight: 600;
// 	font-size: 14px;
// 	line-height: 100%;
// 	color: #000000;
// 	opacity: 0.5;
// }
// .filters {
// 	margin-left: 10px;
// 	display: flex;
// 	flex-direction: column;
// 	// border: 1px solid red;
// 	// width: 50%;
// 	padding-top: 10px;
// 	padding-bottom: 10px;
// 	min-width: 500px;
// }
// .addEvent {
// 	width: 100%;
// 	// height: 50%;
// 	left: 0px;
// 	top: 0px;
// 	background: #ffffff;
// 	border: 1px dashed rgba(0, 0, 0, 0.1);
// 	box-sizing: border-box;
// 	border-radius: 8px;
// 	display: flex;
// 	align-items: center;
// 	// border: 1px solid red;
// }
// .plusIcon {
// 	color: #000000;
// 	margin-left: 8px;
// 	opacity: 0.5;
// 	font-size: 22px;
// }
// .addText {
// 	color: #000000;
// 	margin-left: 8px;
// 	opacity: 0.5;
// 	font-size: 14px;
// }
// .filterRow {
// 	display: flex;
// 	margin-bottom: 12px;
// 	display: flex;
// 	align-items: center;
// }
// .Sequence {
// 	// padding: 4px 10px;
// 	width: 20px;
// 	height: 22px;
// 	background: #bb6bd9;
// 	border-radius: 4px;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	color: #ffffff;
// }
// .filterOne,
// .filterTwo,
// .filterThree {
// 	width: 181px;
// 	height: 30px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	// border: 1px solid black;
// 	border: 0.5px solid #dfdfdf;
// 	box-sizing: border-box;
// 	border-radius: 8px;
// 	margin-left: 5px;
// 	// background: url("../../../assets/filter_icon.png");
// }
// .filterIcon {
// 	// border: 1px solid red;
// 	width: 31px;
// 	height: 100%;
// 	// background: url("../../../assets/filter_icon.png");
// 	background-repeat: no-repeat;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	cursor: pointer;
// }
// .filterIconImage {
// 	width: 55%;
// }
// .dropdown {
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// 	// padding: 8px 12px;
// 	width: 140px;
// 	height: 90%;
// 	background: #ffffff;
// 	// border: 0.5px solid #dfdfdf;
// 	// box-sizing: border-box;
// 	// border-radius: 8px;
// 	font-family: Montserrat;
// 	font-style: normal;
// 	font-weight: 500;
// 	font-size: 12px;
// 	line-height: 100%;
// 	letter-spacing: -0.03em;
// 	color: #828282;
// 	// margin-left: 5px;
// 	// border: 1px solid red;
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	text-indent: 1px;
// 	text-overflow: "";
// 	// margin: 5px;
// 	border: none;
// }
// .dropdown:focus {
// 	outline: none;
// }
// .DeleteIcon {
// 	width: 18px;
// 	margin-left: 10px;
// }
// .DataType {
// 	border-left: 1px solid #d3d3d3;
// 	padding-left: 5px;
// 	width: 31px;
// }
.selectOne {
	// border: 5px solid red;
	// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.filterButton {
	background: white;
	// borderLeft: "none",
	// borderTop: "1px solid #D3D3D3",
	margin-left: -33px;
	z-index: 1;
	// border: "none",
	cursor: pointer;
	// border: 1px solid red;
	height: 30px;
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.FilterIconColor {
	display: none;
	// width: 100%;
	// height: 100%;
}
.FilterIconGrey {
	// width: 100%;
	// height: 100%;
}
.filterButton:hover img[id="initial"] {
	display: none;
}
.filterButton:hover img[id="onhover"] {
	display: block;
}

// .FilterIconColor:hover {
// 	display: block;
// }
