.EachData {
	width: 100%;
	// height: 320px;
	// border: 1px solid red;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 20px;
}
.EachDataNext {
	width: 90%;
	height: 100%;
	// border: 1px solid red;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	// align-items: center;
}
.row {
	width: 31%;
	// border: 1px solid black;
	height: 35px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 100%;
	color: #000000;
	opacity: 0.5;
	// border: 1px solid red;
	display: flex;
	align-items: flex-end;
	padding-bottom: 7px;
}

.title {
	min-width: 35%;
	margin-right: 10px;
	// border: 1px solid red;
}
.data {
	// width: 60%;
	font-weight: 500;
	opacity: 1;
	overflow: hidden;
	cursor: pointer;
	// border: 1px solid red;
}
