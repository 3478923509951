.chart-panel {
  width: 100%;
  height: 100%;
}
.reconfig-button {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 12px;
  margin-top: 20px;
}
