.Sidebar {
	width: 100%;
	height: 100vh;
	padding: 16px;
	// border: 2px solid red;
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: space-between;
	padding-bottom: 0;
}
// section 1
.iconRow {
	width: 84%;
	height: 8vh;
	margin-bottom: 20px;
	// border: 1px solid red;
}
// section 2
.tabSection {
	width: 100%;
}
.navLink {
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	// border: 1px solid red;
	padding-left: 10px;
}
.logo {
	width: 11%;
	// border: 1px solid red;
}
.linkDiv {
	width: 90%;
}
.sidepanelLink {
	width: 100%;
	color: #bdbdbd;
	font-size: 14px;
	font-weight: 600;
	text-decoration: none;
	cursor: pointer;
	// margin-left: 5px;
}
.sidepanelLinkActive {
	width: 100%;
	color: #bb6bd9;
	font-size: 14px;
	font-weight: 600;
	text-decoration: none;
	cursor: pointer;
	// margin-left: 5px;
}
//  section 3
.FilterSection {
	margin-top: 30px;
	width: 100%;
	// height: 60%;
	border-top: 1px solid #dfdfdf;
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.eachFilter {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 40px;
	background: #ffffff;
	border: 1px solid #dfdfdf;
	box-sizing: border-box;
	border-radius: 40px;
	font-size: 14px;
	color: #828282;
	font-family: Montserrat;
	margin-top: 17px;
	cursor: pointer;
}
.filterSelected {
	border: 1px solid #bb6bd9;
	color: #bb6bd9;
}
.eachFilter:hover {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.goButton {
	width: 92px;
	height: 40px;
	background: #bb6bd9;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: #fff;
	margin-top: 40px;
}
// section 4
.shifuLogo {
	// border: 1px solid red;
	width: 100%;
	height: 43vh;
	display: flex;
	justify-content: center;
	padding: 0 10px;
	align-items: flex-end;
}
