// .chart-panel {

//     height: 350px;
//     width: 550px;
//     margin-top: 50px;
//     border: 3px solid #F2F2F2;
//     box-sizing: border-box;
//     border-radius: 40px;
//     position: relative;

// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.chart-header-title {
  color: #808184;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  margin-left: 30px;
  margin-right: 20px;
}
g {
  font-family: "Montserrat";
  color: #969696;
  font-weight: 600;
  font-style: normal;
}
.goButton {
  background-color: #72c8cc;
  border-radius: 15px;
  height: 50px;
  width: 110px;
  font-weight: bold;
  border: 2px solid;
  color: white;
  position: absolute;
  left: 40%;
  top: 80%;
}

// .reconfig-button {
// 	margin-top: 21px;
// 	vertical-align: top;
// 	margin-left: 33px;
// }

.chart-background {
  /* background-image: radial-gradient(6px 1px at center, #E0E0E0 20px, transparent 16px);
    background-size: 40px 60px, 120px 40px, 12px 60px, 12px 60px;
    background-position: 0px 0px, 0px 0px, 0px -10px, 0px 10px;  */
}

// .selection-modifier {
// 	width: 130px;
// 	height: 60px;
// 	margin: 20px;
// 	border-radius: 20px;
// 	border: 3px solid #F2F2F2;
//     box-sizing: border-box;
//     border-radius: 40px;
// 	color: #828282;
// 	text-align: center;
// }

// .chartType-selection {
// 	width: 130px;
// 	height: 60px;
// 	margin: 20px;
// 	border-radius: 20px;
// 	border: 3px solid #F2F2F2;
//     box-sizing: border-box;
//     border-radius: 40px;
// 	color: #828282;
// 	text-align: center;
//     position: absolute;
//     top: -30%
// }

// .chart-headerDiv {
//     // border: 2px solid black;
//     top: -30%;
//     position: absolute;
//     margin-top: 30px;
// }
.bucket-div {
  color: #828282;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.bucket-dropdown {
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #bb6bd9;
  width: 50px;
  color: #828282;
  margin-left: 10px;
  border-radius: 5px;
}
.cummulative-div {
  color: #828282;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  // line-height: 143%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 27px;
  margin-right: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #72c8cc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #72c8cc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

input:checked + .slider {
  background-color: #72c8cc;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.line-chart {
  display: none;
}
