// .selection-panel {
//     border-radius: 45px;
//     border: 3px solid rgba(201, 200, 200, 0.644);
//     height: 380px;
//     width: 650px;
//     margin-left: 30px;
//     margin-top: 20px;

// }

.selection-panel {
  width: 100%;
  height: 85%;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
}
.steps-selector-wrapper {
  width: 100%;
  height: 250px;
  display: flex;
  order: 1;
  flex-direction: column;
  // border-left: 3px solid #f2f2f2;
  padding-left: 20px;
  // margin-top: 35px;
  overflow: auto;
}

.game-div {
  display: inline-block;
  vertical-align: top;

  display: flex;
  align-content: center;
  justify-content: space-around;
}

.selection-modifier {
  #feature-select-Date-0 {
    display: none;
  }
  width: 150px;
  height: 68px;
  margin: 30px;
  border-radius: 20px;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  color: #828282;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selection-modifier-funnel {
  #feature-select-Date-0 {
    display: none;
  }
  width: 150px;
  height: 68px;
  margin: 30px;
  border-radius: 20px;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  color: #828282;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selection-modifier-funnel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 48px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 15px;
  border-radius: 20px;
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 40px;
  color: #828282;
  text-align: center;
}
.game-title-a {
  // position: absolute;
  // left: 25px;
  // top: 15px;
  height: 32px;
  width: 115px;
  text-decoration: none;
  color: #828282;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-nav {
  position: relative;
  width: 150px;
  height: 68px;
  margin: 30px;
  border-radius: 40px;
  box-sizing: border-box;
  text-align: center;
  border: 3px solid #f2f2f2;
}
.forward-span::after {
  position: absolute;
  content: "";
  border-left: 3px solid #73c8cc;
  border-top: 3px solid #73c8cc;

  padding: 5px;
  right: 28px;
  top: 4%;
  transform: rotate(135deg);
}

.shake-select {
  animation: shake 0.82s cubic-bezier(0.26, 0.05, 0.8, 0.37) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  border: 3px solid #ffe6d0;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.close-selection-lable {
  position: absolute;
  right: 0;
  margin-right: 20px;
  top: 0;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 800;
  color: #828386;
  cursor: pointer;
}
.close-lable-hidden {
  display: none;
}
