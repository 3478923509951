.date-range-btn {
  background: white;
  border: 1px solid #8080805e;
  font-size: 0.85rem;
  padding-left: 15px;
  color: #828282;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.date-range-btn:hover {
  //   border: 1px solid black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: #bb6bd9;
}
.date-range-last-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.date-range-first-btn {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.hiddenDateRange {
  display: none;
}
.visibleDateRange {
  display: inline-block;
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: fit-content;
  color: red;
  margin-top: 2%;
}
.rdrMonth {
  background-color: white;
}
.rdrMonthAndYearWrapper {
  background-color: white;
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #777373c7;
  border-radius: 3px;
  background-color: white;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #666565da;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
tr:hover {
  background-color: rgba(96, 93, 95, 0.05);
}
.metricview-box {
  flex: 1;
  border: 1px solid #000;
}
