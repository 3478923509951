.date-range-btn {
  background: white;
  border: 1px solid #8080805e;
  font-size: 0.85rem;
  padding-left: 15px;
  color: #828282;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.date-range-btn:hover {
  //   border: 1px solid black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: #bb6bd9;
}
.date-range-last-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.date-range-first-btn {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
