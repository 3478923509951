.main-header {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-right: 250px;
  margin-bottom: 50px;
}

.header-title {
  font-family: "Montserrat";
  font-weight: bolder;
  font-size: 30px;
  color: rgba(72, 74, 79, 0.7);
  letter-spacing: -0.05em;
  margin-left: 20px;
  font-style: normal;
  align-self: center;
}
.header-text {
  width: 120px;
  height: 22px;
  margin-left: 17px;
  margin-top: 10px;
}
