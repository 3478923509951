body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #828282;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.goButton {
  background-color: #72c8cc;
  border-radius: 15px;
  height: 50px;
  width: 110px;
  font-weight: bold;
  border: 2px solid;
  color: white;
  position: absolute;
  left: 40%;
  top: 75%;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-menu {
  border-radius: 18px;
}

.dropdown-submenu > .dropdown-menu-left-open {
  top: 0;
  right: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
  font-size: 0.85rem;
  border-radius: 18px;
}
.dropdown-submenu > .dropdown-menu-right-open {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
  font-size: 0.85rem;
  border-radius: 18px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.reconfig-button {
  margin-top: 21px;
  vertical-align: top;
  /* margin-left: 22px; */
}

.selection-modifier {
  width: 130px;
  height: 60px;
  margin: 20px;
  border-radius: 20px;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 40px;
  color: #828282;
  text-align: center;
}
.buckets-div {
  position: absolute;
  margin-bottom: 10px;
  border: 2px solid black;
}
.chart-header {
  border: 2px solid black;
  height: 50px;
  /* width: 100px; */
  z-index: 1;
  position: absolute;
  top: -20%;
}

.chart-header-span {
  font-weight: 700;
  size: 2rem;
  margin-top: 6px;
  color: #eb9036f0;
}

.funnel-filter-btn:hover img[id="initial"] {
  display: none;
}
.funnel-filter-btn:hover img[id="onhover"] {
  display: block;
}

.FilterIconColor {
  display: none;
}
