.selection-modifier-insight {
  justify-content: center;
  align-items: center;
  width: 8.5vw;
  //   width: auto;
  max-width: 137px;
  height: 40px;
  margin-left: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  margin-right: 0.15vw;
  border: 1px solid rgba(170, 170, 170, 0.418);
  box-sizing: border-box;
  border-radius: 13px;
  color: #828282;
  text-align: center;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.featureType-selection {
  border: none;
  text-decoration: underline;
  font-weight: 500;
  height: 40px;
  width: 4.6vw;
  justify-self: center;
  align-self: center;
  color: #828282;
  border-radius: 10px;
  margin-bottom: 10px;
}
.go-button-insight {
  width: 100px;
  border-radius: 10px;
  background: #72c8cc;
  color: white;
  font-weight: 700;
  border: none;
  height: 34px;
}
.insight-head-selection {
  border-radius: 13px;
  height: 36px;
  padding: 7px;
  margin-right: 20px;
  border: 1px solid #dfdfdf;
  color: #828282;
  font-weight: 500;
}
.span-metric-view-1 {
  font-weight: 500;
  align-content: center;
  text-align: center;
  font-size: 1.65rem;
  color: #727272;
}
.span-metric-view-2 {
  font-weight: 600;
  align-content: center;
  text-align: center;
  font-size: 2rem;
  color: #4c4b4b;
}

.selection-modifier-insight:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
