.queries-anchor {
  text-decoration: none;
  color: black;
  size: 0.8rem;
}
.saved-query-parent {
  background-color: white;
  border: 1px solid #d5a5e7;
  border-radius: 20px;
  height: 17vh;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
}
.saved-query-time {
  font-size: 0.6vw;
  color: rgb(75, 72, 72);
}
.saved-query-time {
  // position: absolute;
  // right: 0;
  margin-left: 2.5vmin;
  margin-top: 2.5vh;
  margin-right: 1.5vw;
}
.delete-icon {
  height: 15px;
  width: 12px;
}
