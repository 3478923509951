.main-screen {
  position: relative;

  @media (min-width: 769px) {
    margin-left: 300px;
  }
  @media (max-width: 769px) {
    margin-left: 280px;
  }

  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.addButton {
  border-radius: 26px;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 14px;
  height: 60px;

  background-color: white;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  color: #828282;
  font-family: "Montserrat", sans-serif;
  flex-basis: 100%;
  width: 90px;
  position: absolute;
  top: 100%;
}

.chart-selection {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #828282;
  align-self: flex-end;
}

.add-panel-container {
  border: 2px dashed #dfdfdf;
  border-radius: 20px;
  height: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.add-panel-text {
  font-size: 18px;
  font-weight: 600;
  color: "#828282";
  margin-left: 5px;
}
.add-panel-container:hover .add-panel-text {
  font-size: 20px;
}

.dashboard-panels {
  @media (min-width: 1600px) {
    min-width: 600px;
    max-width: 700px;
  }
  @media (max-width: 1599px) {
    min-width: 500px;
    max-width: 580px;
  }
  @media (max-width: 1240px) {
    min-width: 500px;
    max-width: 580px;
  }
}
