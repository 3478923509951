.selection-modifier-insight {
  justify-content: center;
  align-items: center;
  width: 8.5vw;
  //   width: auto;
  max-width: 137px;
  height: 40px;
  margin-left: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  margin-right: 0.15vw;
  border: 1px solid rgba(170, 170, 170, 0.418);
  box-sizing: border-box;
  border-radius: 13px;
  color: #828282;
  text-align: center;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.featureType-selection {
  border: none;
  text-decoration: underline;
  font-weight: 500;
  height: 40px;
  width: 7vw;
  justify-self: center;
  text-align: end;
  align-self: center;
  color: #828282;
  border-radius: 10px;
  margin-bottom: 10px;
}

.featureType-selection:hover {
  color: rgb(32, 32, 32);
}

.go-button-funnel {
  width: 100px;
  border-radius: 10px;
  background: #72c8cc;
  color: white;
  font-weight: 700;
  border: none;
  height: 34px;
}

.go-button-funnel:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.dropoff-main-select {
  justify-content: center;
  align-items: center;
  width: 10.5vw;
  //   width: auto;
  //   max-width: 230px;
  height: 40px;
  margin-left: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  margin-right: 0.15vw;
  border: 1px solid rgba(170, 170, 170, 0.418);
  box-sizing: border-box;
  border-radius: 13px;
  color: #828282;
  text-align: center;
  font-size: 15px;
}

.bucket-select-dropoff {
  border: 2px solid rgb(252 255 252);
  border-radius: 8px;
  color: white;
  font-weight: 700;
  background: #72c8cc;
  width: 50px;
}

.dropoff-main-select:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.dropdown-menu-settings {
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-right: 45px;
  width: 220px;
  box-shadow: 0 0 13px -6px #000000;
}

.dropdown-item-settings {
  color: #828282;
  font-weight: 500;
  margin-bottom: 6px;
  display: inline-flex;
  width: 90%;
  background-color: #ffffffc7;
  border: 1px solid #d5d1d145;
  font-size: 14px;
}
.dropdown-item-settings:active {
  background-color: #bb6cd9;
}
.dropdown-item-settings:hover {
  color: #bb6cd9;
  background: white;
  // border: 1px solid;
}
.dropdown-submenu-settings {
  display: flex;
  justify-content: center;
}
.dropdown-submenu-settings > a:after {
  content: none;
}
