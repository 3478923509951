.search-input {
  border-radius: 20px;
  height: 7vh;
  border: 1px solid #dbd6d6;
  color: #b8b1b1;
  width: 50%;
  margin: auto;
  font-size: 0.88rem;
}
.queries-span {
  font-weight: 600;
  cursor: pointer;
}
.span-selected {
  font-weight: 700;
  color: black;
}
.search-input:focus {
  color: rgb(114, 114, 114);
}

.query-builder-div {
  // border: 1px solid #b6b6b6cc;
  // border-radius: 30px;
  height: 85%;
  width: 100%;
  margin-top: 2vh;
}
.query-area-parent {
  display: flex;
  margin-top: 1vh;
}
.querypanel-button {
  background-color: #72c8cc;
  height: 36px;
  max-width: 100%;
  font-size: 1rem;
  font-weight: 500;
  border: 2px solid;
  color: white;
  margin-left: 0.75vw;
  border-radius: 10.7692px;
  text-align: inherit;
  width: 136px;
  .play-icon {
    // width: 15.71px;
    // height: 12.24px;
    margin: 10px 5px 10px 5px;
  }
  .save-icon {
    width: 11px;
    height: 14px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .save-as-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .csv-icon {
    width: 20px;
    height: 15px;
  }
}
.download-csv-button {
  border: 1px solid rgba(189, 189, 189, 0.2);
  box-sizing: border-box;
  border-radius: 50px;
  margin-left: 0.75vw;
  background: white;
  height: 52px;
  width: 79px;
  .play-icon {
    // width: 15.71px;
    // height: 12.24px;
    margin: 10px 5px 10px 5px;
  }
  .csv-icon {
    width: 24px;
    height: 33px;
  }
}

.arrow-query::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-left: 1.5em;
  border: 0.5em solid transparent;
  border-left-color: gray;
  transform-origin: 0 50%;
  transition: transform 0.25s;
  margin-top: 0.25em;
}

.arrow-query.down::after {
  transform: rotate(90deg);
  transition: transform 0.25s;
  margin-top: 0em;
}

.arrow-table::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-left: 1.5em;
  border: 0.5em solid transparent;
  border-left-color: gray;
  transform-origin: 0 50%;
  transition: transform 0.25s;
  margin-top: 0.25em;
}

.arrow-table.down::after {
  transform: rotate(90deg);
  transition: transform 0.25s;
  margin-top: 0em;
}
.query-builder-div.closed {
  display: none;
  overflow: hidden;
}

.tableview-table.closed {
  display: none;
  overflow: hidden;
}

#editor * {
  font-family: monospace !important;
  font-size: 16px !important;
  direction: ltr !important;
  text-align: left !important;
}
.query-badges {
  border-radius: 1vw;
  font-size: 0.8vw;
  background-color: #84aeb5;
  color: white;
  border: 0px;
  margin-right: 1vw;
  font-family: "Montserrat";
  font-weight: 500;
}
.dropdown-item {
  cursor: pointer;
}

#tables-div-lable {
  border: 2px solid #b9b9b9;
  border-radius: 15px;
  font-size: 1rem;
  background: white;
  text-decoration: none;
  color: #484545;
  padding: 0.3vw;
}
.data-download {
  margin-top: 12px;
}
.no-display {
  display: none;
}
